<template>
  <section class="Add-Content">
    
    <div class="page-header">
      <h3 class="page-title">Add User</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/users/users" class="text-primary">User
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Add User</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="addUser">
              <div class="mb-1">
                <!-- <label class="border-order-label">Add User</label> -->
                <div  >
                  <div class="pt-1">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <label for="name">First Name</label>
                              <input v-model="user.first_name" type="text" class="form-control" :class="{
                                'is-invalid':
                                  submitted && $v.user.first_name.$error,
                              }" />
                              <div v-if="submitted && !$v.user.first_name.require" class="invalid-feedback">
                                First Name is required
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <label for="name">Last Name</label>
                              <input v-model="user.last_name" type="text" class="form-control" :class="{
                                'is-invalid':
                                  submitted && $v.user.last_name.$error,
                              }" />
                              <div v-if="submitted && !$v.user.last_name.require" class="invalid-feedback">
                                Last Name is required
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <label for="name">Username</label>
                              <input v-model="user.username" type="text" class="form-control" :class="{
                                'is-invalid':
                                  submitted && $v.user.username.$error,
                              }" />
                              <div v-if="submitted && !$v.user.username.require" class="invalid-feedback">
                                User Name is required
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <label for="name">Role</label>
                              <multiselect v-model="user.role" :options="selectRole" 
                          :class="{
                            'is-invalid':
                              submitted && $v.user.role.$error,
                          }"
                        ></multiselect>
                        <div
                          v-if="
                            submitted && !$v.user.role.required
                          "
                          class="invalid-feedback"
                        >
                          Status is required
                        </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <label for="name">Email</label>
                              <input v-model="user.email" type="text" class="form-control" :class="{
                                'is-invalid': submitted && $v.user.email.$error,
                              }" />
                              <div v-if="submitted && $v.user.email.$error" class="invalid-feedback">
                                <span v-if="!$v.user.email.required">Email is required</span>
                                <span v-if="!$v.user.email.email">Email is invalid</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <label for="name">avater</label>
                              <b-button v-b-modal.modal-xl class="choose-image">Choose Image</b-button>

                        <chooseImage @imageId="setImageId"></chooseImage>
                        <vue-dropzone ref="myVueDropzone" id="thumbVideo " :options="dropzoneOptions"
                          :useCustomSlot="true" v-on:vdropzone-success="success" @vdropzone-sending="updateAvater">
                          <div class="dropzone-custom-content">
                            <div class="dropzone-custom-content">
                              <div v-if="bannerImg">
                                <div class="image-size-dropzone">
                                  <img :src="`${imageApiURL}/${bannerImg.thumb_s}`" class="show-img img-fluid" />
                                </div>
                                <div class="subtitle pt-1 pb-0">
                                  <span> Change </span>
                                  <span style="color: #eb2027"> Browse</span>
                                </div>
                              </div>
                              <div v-if="!bannerImg" class="pt-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 24 24"
                                  fill="transparent" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" class="feather feather-upload" color="">
                                  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                  <polyline points="17 8 12 3 7 8"></polyline>
                                  <line x1="12" y1="3" x2="12" y2="15"></line>
                                </svg>
                                <div class="subtitle pt-1 pb-5">
                                  <span> Drop File or </span>
                                  <span style="color: #eb2027"> Browse</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </vue-dropzone>
                            </div>
                          </div>
                        </div>
                      </div>



                      <div class="col-12 pt-1">
                        <button class="btn btn-primary btn-md btn-block">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { required, email } from "vuelidate/lib/validators";
import axios from "axios";
const mediaApiURL = process.env.VUE_APP_MEDIA_SERVICE;
const mediaApi = process.env.VUE_APP_MEDIA_SERVICE;
const imageApiURL = process.env.VUE_APP_IMAGE_URL;
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { userApi } from "../../api";
import Multiselect from "vue-multiselect";

import chooseImage from "../../components/chooseImage";
export default {
  name: "add-user",
  components: {
     Multiselect, vueDropzone: vue2Dropzone, chooseImage,
  },
  data() {
    return {
      dropzoneOptions: {
        url: `${mediaApiURL}/file/upload`,
      },
      user: {
        first_name: "",
        last_name: "",
        username: "",
        email: "",
       
      },
      
      submitted: false,
      role: "",
      selectRole: [],
      
      info: "",
      imageApiURL, bannerImg: "",
    };
  },
  validations: {
    user: {
      first_name: { required },
      last_name: { required },
      username: { required },
      email: { required, email },
      role:{ required },
    },
  },
  mounted() {
    this.getRoles();
  },
  methods: {
      setImageId(selectedId) {
      
      axios.get(`${mediaApi}/files/${selectedId}`).then((response) => {
        this.bannerImg = response.data.data[0];
        
      });
    },

    async addUser() {
      this.submitted = true;

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if(this.bannerImg){
        this.user.image_id = this.bannerImg.id
      }
      this.user.role = [this.user.role]
      
      const { data ,code } = await userApi.addUser({
        ...this.user,
      });
      
      this.$router.push("/users/users");
      if (code == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: messages,
        });
      }
    },
    updateAvater(file, xhr, formData) {
      let folder = "products";
      xhr.setRequestHeader("Header", "");
      formData.append("folder", folder);
    },
    success(file, response) {
      this.user.image_id = response.data.id;
      this.bannerImg = ""
      if (response.status == "success") {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: "Updated successfully",
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: "The given data was invalid",
        });
      }
    },
    async getRoles() {
      
      const { data } = await userApi.getRole();
      this.selectRole = data.data.data.cms_roles
      
    },
  },
};
</script>


<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>
<style scoped>
.border-order-label {
  background: #fff;
  margin-left: 22px;
  padding: 0px 9px;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 15px;
}

.form-group label {
  font-size: 12px;
}

.dropzone {
  padding: 0px 20px !important;
}
</style>